import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

// eslint-disable-next-line
export const ImpressumPageTemplate = ({
  contact,
  address,
  disclaimer,
  copyright,
  link_disclaimer,
  trade_register,
  mwst_number,
  lang,
}) => {
  let address_title
  let contact_title
  let trademark_title
  let disclaimer_title
  let link_disclaimer_title
  let copyright_title

  if (lang === 'fr') {
    address_title = 'Adresse de contact'
    contact_title = 'Personnes autorisées à signer le contrat'
    trademark_title = 'Inscription au registre du commerce'
    disclaimer_title = 'Clause de non-responsabilité'
    link_disclaimer_title = 'Responsabilité pour les liens'
    copyright_title = "Droit d'auteur"
  } else {
    address_title = 'Kontaktadresse'
    contact_title = 'Vertragsberechtigte Personen'
    trademark_title = 'Handelsregistereintrag'
    disclaimer_title = 'Haftungsausschluss'
    link_disclaimer_title = 'Haftung für Links'
    copyright_title = 'Urheberrecht'
  }

  const contacts = []

  contact.forEach((c, index) => {
    contacts.push(
      <span className="d-block" key={`contact-${index}`}>
        {c.name}, {c.function}
      </span>,
    )
  })

  return (
    <div className="container content-container">
      <h2>{address_title}</h2>
      <p>
        {address.company}
        <br />
        {address.street}
        <br />
        {address.zip} {address.city}
        <br />
        {address.country}
      </p>
      <p>{address.mail}</p>
      <h2>{contact_title}</h2>
      <p>{contacts}</p>
      <h2>{trademark_title}</h2>
      <p>
        {trade_register.company_description}: {address.company}
        <br />
        {trade_register.number_description}: {trade_register.number}
        <br />
        {mwst_number.description}: {mwst_number.number}
      </p>
      <h2>{disclaimer_title}</h2>
      <p>{disclaimer}</p>
      <h2>{link_disclaimer_title}</h2>
      <p>{link_disclaimer}</p>
      <h2>{copyright_title}</h2>
      <p>{copyright}</p>
    </div>
  )
}

ImpressumPageTemplate.propTypes = {
  contact: PropTypes.arrayOf(
    PropTypes.shape({
      function: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  address: PropTypes.shape({
    city: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    mail: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    zip: PropTypes.number.isRequired,
  }).isRequired,
  disclaimer: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
  link_disclaimer: PropTypes.string.isRequired,
  trade_register: PropTypes.shape({
    company_description: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    number_description: PropTypes.string.isRequired,
  }).isRequired,
  mwst_number: PropTypes.shape({
    description: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
}

const ImpressumPage = ({ data, pageContext }) => {
  const {
    contact,
    address,
    disclaimer,
    copyright,
    link_disclaimer,
    trade_register,
    mwst_number,
  } = data.markdownRemark.frontmatter
  return (
    <Layout pageContext={pageContext}>
      <ImpressumPageTemplate
        contact={contact}
        address={address}
        disclaimer={disclaimer}
        copyright={copyright}
        link_disclaimer={link_disclaimer}
        trade_register={trade_register}
        mwst_number={mwst_number}
        lang={pageContext.lang}
      />
    </Layout>
  )
}

ImpressumPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape(ImpressumPageTemplate.propTypes.isRequired)
        .isRequired,
    }),
  }),
  pageContext: PropTypes.object.isRequired,
}

export default ImpressumPage

export const impressumPageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        contact {
          function
          name
        }
        address {
          city
          company
          country
          mail
          street
          zip
        }
        disclaimer
        copyright
        link_disclaimer
        trade_register {
          company_description
          number
          number_description
        }
        mwst_number {
          description
          number
        }
      }
    }
  }
`
